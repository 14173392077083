<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      fill="currentColor"
      d="M.333 3.217V.333h2.884v1H1.333v1.884h-1Zm0 10.45v-2.883h1v1.883h1.884v1H.333Zm10.45 0v-1h1.884v-1.883h1v2.883h-2.884Zm1.884-10.45V1.333h-1.884v-1h2.884v2.884h-1Zm-1.867 7.6h1.05v1.05H10.8v-1.05Zm0-2.1h1.05v1.05H10.8v-1.05Zm-1.05 1.05h1.05v1.05H9.75v-1.05Zm-1.05 1.05h1.05v1.05H8.7v-1.05Zm-1.05-1.05H8.7v1.05H7.65v-1.05Zm2.1-2.1h1.05v1.05H9.75v-1.05ZM8.7 8.717h1.05v1.05H8.7v-1.05Zm-1.05-1.05H8.7v1.05H7.65v-1.05Zm4.2-5.533v4.2h-4.2v-4.2h4.2ZM6.333 7.667v4.2h-4.2v-4.2h4.2Zm0-5.533v4.2h-4.2v-4.2h4.2Zm-.833 8.9V8.5H2.967v2.534H5.5Zm0-5.534V2.967H2.967V5.5H5.5Zm5.517 0V2.967H8.483V5.5h2.534Z"
    />
  </svg>
</template>
